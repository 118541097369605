import { useCallback, useEffect, useRef, useState } from 'react';
import { Client, gql } from 'urql';
import { SaveAssociateLoginDocument } from '../graphql/generated';

gql`
  mutation saveAssociateLogin(
    $nodeId: String
    $associateIds: [String]
    $workstationId: String
    $clientType: String
    $role: String
  ) {
    saveAssociateLogin(
      nodeId: $nodeId
      associateIds: $associateIds
      workstationId: $workstationId
      clientType: $clientType
      role: $role
    )
  }
`;

export const FETCH_INTERVAL_MS = 60000;

type SaveAssociateLoginResult = {
  loading: boolean;
  error?: unknown;
};

interface useSaveAssociateLoginProps {
  client: Client;
  disabled?: boolean;
  associateIds: string[];
  clientType: string;
  role?: string;
}

const useSaveAssociateLogin = ({
  client,
  disabled,
  associateIds,
  clientType,
  role,
}: useSaveAssociateLoginProps): SaveAssociateLoginResult => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<unknown | undefined>();
  const fetchInterval = useRef<number | undefined>();

  const saveAssociateLogin = useCallback(async () => {
    setLoading(true);
    client
      .mutation(SaveAssociateLoginDocument, {
        associateIds,
        clientType,
        role,
      })
      .toPromise()
      .catch(setError)
      .finally(() => setLoading(false));
  }, [client, associateIds, role, clientType]);

  useEffect(() => {
    if (!disabled) {
      saveAssociateLogin();
      fetchInterval.current = window.setInterval(
        saveAssociateLogin,
        FETCH_INTERVAL_MS
      );
    }

    return () => {
      clearInterval(fetchInterval.current);
    };
  }, [disabled, fetchInterval.current, saveAssociateLogin, associateIds]);

  return {
    loading,
    error,
  };
};

export default useSaveAssociateLogin;
